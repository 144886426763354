@import 'icon';   //匯入拼接小圖


@include spritesCSS("icon");

#content-frame{
	@media screen and (max-width: 1200x){
		padding:30px 0;
	}
}


#wrapper{background: #fff;}

/* 瀏覽方式 結束 */

.product-list-frame{
	width: 100%;
	margin:auto auto 60px auto;

	&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
		visibility: hidden;
	}

	/* 產品列表menu 開始 */
	.product-list-menu{
		width: 210px;
		float: left;
		padding-right: 15px;

		@media screen and (max-width: 991px){
			width: 100%;
			padding:0;
		}

		ul.pc{
			width: 100%;

			@media screen and (max-width: 991px){display: none;}

			li{
				@media screen and (max-width: 991px){display: inline;}

				h2{
					color: #808080;
					border-bottom: 1px #808080 solid;
					display: block;
					padding: 10px 0;
					font-size: 14px;
					margin:0 0 10px 0;
					font-family: 'Montserrat-Medium','Noto_Medium';
				}

				ul{
					margin-bottom: 15px;

					@media screen and (max-width: 991px){
						display: none;
					}

					li{
						margin-bottom: 10px;

						h3{margin: 0; font-weight: bold;}
						h3>a{font-size: 14px;}

						h3 a{
							font-family: 'Montserrat-Medium','Noto_Medium';
						}

						h4{margin: 0;}

						a{
							font-size: 14px;
							color: #0C0C0C;
							letter-spacing: 0.075em;
							line-height: 25px;
							display: block;
							white-space:nowrap;
							text-overflow:ellipsis;
							overflow:hidden;
							margin-bottom: 5px;

							&:hover{
								color: #aaa;
							}
						}
					}
				}
			}
		}

		ul.mobile{
			display: none;
			text-align: center;
			@media screen and (max-width: 991px){
				display: block;
			}

			h2{
				font-size: 14px;
				margin: 0;
				display: inline;
			}

			span{
				color: #0C0C0C;
			    font-size: 14px;
			    letter-spacing: 0.2em;
			    line-height: 45px;
			    font-weight: bold;
			    border-bottom: 1px #333 solid;
			}

			li{
				display: inline-block;

				&:after {
					content: "/";
					display:inline-block;
					line-height: 24px;
				    font-size: 12px;
				    color: #0C0C0C;
				    padding: 0 2px;
				}

				&:last-child:after{content:" ";}

				h3{
					display: inline;
					font-size: 12px;
					font-weight: normal;
				}

				a{
					line-height: 24px;
				    font-size: 12px;
				    color: #0C0C0C;
				    letter-spacing: 0.1em;

				    &.active{border-bottom: 1px #0C0C0C solid;}
				}
			}
		}
	}
	/* 產品列表menu 結束 */

	.product-list-right{
		float: right;
	    width: calc( 100% - 210px);

	    &.full{width: 100%;}

	    @media screen and (max-width: 991px){
	    	width: 100%;
	    }

	    .list-banner{
	    	img{
	    		max-width: 100%;
	    	}
	    }
	}

	/* 篩選項目 開始 */
	.filter-options{
		margin:auto;
		position: relative;
		padding: 0 5px;

		&:after{
			content: " ";
			display: block;
			height: 0;
			clear: both;
			visibility: hidden;
		}

		@media screen and (max-width: 991px){
			height: 50px;
			background: #fff;
			border-bottom:1px solid #333;
			padding: 0;
		}

		.filter-project{
			float: left;
			text-align: left;
			margin:0;

			@media screen and (max-width: 991px){
				width: 100%;
				display: flex;
			}

			li{
				padding: 6px 0 9px 0;
				display: inline-block;


				@media screen and (max-width: 991px){
					width: 50%;
					text-align: center;
					padding: 15px 0;
				}
			}

			span{
				color: #0C0C0C;
				font-size: 12px;
				font-family: Montserrat-Medium;
			}

			&.fix{
				@media screen and (max-width: 1024px){
					position: fixed;
					top: 60px;
					z-index: 2;
					border-bottom: 1px solid #0C0C0C;
					background: #fff;
					left: 0;
				}
			}
		}

		.filter-project-view {
		  	label {
			  	color: #c5c5c5;
			    font-size: 12px;
			    cursor: pointer;
			    padding:0 5px;

			    &:after,&:before{display: none;}
			  }

			input {
			    position: absolute;
			    left: -9999px;
		  	}

		  	input:checked + label {
			    color: #0C0C0C;
			    text-decoration: underline;
		  	}
		}

		select{
			background: url(../img/select-arrow.png) no-repeat right 7px;
			appearance:none;
			-moz-appearance:none;
			-webkit-appearance:none;
			width: 110px;
			border: 0;
			outline:none;
			font-size: 12px;
			color: #0C0C0C;
			padding:0 10px;
			cursor: pointer;
			background-size: 22px 8px;
			font-family: Montserrat-Medium;

			@media screen and (max-width: 991px){
				text-align-last: center;
				width: 100%;
				padding:0 5px;
			}
		}

		.view-quantity{
			width: 100px;
			float: right;
			height: 38px;
			position: relative;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			justify-content:flex-end;

			li{
				display: inline-block;

				&:nth-child(1){
					&:after{
						content:'|';
						display: inline-block;
						font-size: 14px;
						position: relative;
						top: 0px;
						padding:0 5px;
					}

				}

				&:last-child{
					a{
						i{margin-right: 0;}
					}
				}

				&.active{
					a{
						i{
							@extend .icon;
							@extend %icon-view_black;
							display: inline-block;
						}
					}
				}

				a{
					i{
						@extend .icon;
						@extend %icon-view_black;
						display: inline-block;
						margin:1px;
						vertical-align: bottom;
					}
				}
			}
		}
	}
	/* 篩選項目 結束 */
}

/* 產品列表 開始 */

.product-list-box{
	display:flex;
	flex-wrap:wrap;
	width: 100%;

	&.view-4{
		.product-list-content{
			width: 25%;
			margin-bottom: 20px;
			padding:0 4px;

			@media screen and (max-width: 800px){width: 50%;}
		}
	}

	&.view-2{
		.product-list-content{
			width: 50%;
			margin-bottom: 20px;
			padding:0 4px;

			@media screen and (max-width: 800px){
				width: 50%;
				padding:0 5px;
			}
		}
	}

	.commodity-box{
		position: relative;
		overflow: hidden;

		.sale-icon{
			width: 80px;
			height: 80px;
			position: absolute;
			top: 10px;
			z-index: 2;
			left: 10px;

			img{
				width: 80px;
				height: 80px;
			}

			@media screen and (max-width: 1024px){
				width: 50px;
				height: 50px;

				img{
					width: 50px;
					height: 50px;
				}
			}
		}

		.commodity-img{
			width: 100%;
			position: relative;
			overflow: hidden;
			cursor: pointer;
			display: block;

			img{width: 100%;}

			.sold{
				position: absolute;
				width: 100%;
				height: 100%;
				background:rgba(0,0,0,0.5);
				color: #fff;
				font-weight: bold;
				font-size: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: Montserrat-Medium;
				z-index: 1;
			}
		}
	}

	.product-list-information{
		margin: 10px 0;
		font-size: 14px;
		padding: 0 20px;

		@media screen and (max-width: 991px){padding: 0;}

		.product-list-tltie {
			margin-bottom: 10px;
			text-align: center;
			h2{
				font-size: 12px;
				margin: 0;
				font-weight: normal;
			}
			span{
				color: #0C0C0C;
				font-size: 12px;
				letter-spacing: 0.2em;
				line-height: 20px;
				border: 1px solid #777;
				padding:0 5px;
				font-family: 'Montserrat-Medium',' Noto_Medium';
				display: inline-block;
			}
		}

		.product-list-size {

		  	label {
			  	color: #0C0C0C;
			    margin: 0 0.3em;
			    cursor: pointer;
			    padding:0;
			    font-family: 'Montserrat-Medium',' Noto_Medium';
			    &:before,&:after{display: none;}
			    &:hover {
			      	text-decoration: underline;
			    }
		  	}

		  	input {
			    position: absolute;
			    left: -9999px;
		  	}

		  	input:checked + label {text-decoration: underline;}

		  	input[disabled] + label {color: #c5c5c5;}
		}

		.product-list-name h1{
			font-size: 14px;
			font-weight: normal;
			margin: 0;
		}

		.product-list-name a{
			color: #0C0C0C;
			letter-spacing: 0.2em;
			padding: 9px 0;
			font-family: 'Montserrat-Medium',' Noto_Medium';
			display: block;
			overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		}

		.product-list-price {
			position: relative;
			padding-right: 70px;

			span{
				color: #0C0C0C;
				letter-spacing: 0.2em;
				margin-right: 10px;
				font-family: 'Montserrat-Medium',' Noto_Medium';

				&.original-price{
					color: #0C0C0C;
					text-decoration: line-through;
				}

				&.sale{
					color: #c1272d;
					margin:5px 0;
				}
			}
		}

		.cart-box{
			position: absolute;
			display: flex;
			top: 0;
			right: 0;
			bottom: 0;
			height: 20px;
			margin: auto;
			align-items: center;
			.favore{
				@extend .icon;
				  @extend %icon-heart-empty;
				  text-indent: -999px;
				  overflow: hidden;
				  margin-right: 10px;

				  &.active{
					@extend .icon;
					@extend %icon-heart-full;
				  }
			}

			.cart-btn{
				@extend .icon;
				  @extend %icon-cart;
				text-indent: -999px;
				overflow: hidden;
			}
		}
	}
}


/* 產品列表 結束 */

.display-in{
	display: none;

	@media screen and (max-width: 991px){display: inline !important;}
}

