@charset "UTF-8";
.icon-ad-close {
  width: 30px;
  height: 30px;
  background-position: -239px -128px;
}

.icon-arrow-top {
  width: 50px;
  height: 50px;
  background-position: -68px -128px;
}

.icon-arrow_next {
  width: 14px;
  height: 22px;
  background-position: -40px -230px;
}

.icon-arrow_prev {
  width: 14px;
  height: 22px;
  background-position: -58px -230px;
}

.icon-back_arrow {
  width: 18px;
  height: 18px;
  background-position: -118px -196px;
}

.product-list-box .product-list-information .cart-box .cart-btn, .icon-cart {
  width: 20px;
  height: 19px;
  background-position: -346px -33px;
}

.icon-circle-arrow {
  width: 30px;
  height: 30px;
  background-position: -273px -128px;
}

.icon-clock {
  width: 20px;
  height: 20px;
  background-position: -226px -33px;
}

.icon-close-btn {
  width: 20px;
  height: 20px;
  background-position: -250px -33px;
}

.icon-collection-black-m {
  width: 20px;
  height: 20px;
  background-position: -274px -33px;
}

.icon-collection-black {
  width: 22px;
  height: 22px;
  background-position: -174px -33px;
}

.icon-delete {
  width: 16px;
  height: 16px;
  background-position: -140px -196px;
}

.icon-favorite {
  width: 22px;
  height: 22px;
  background-position: -200px -33px;
}

.icon-favorite_heart_gray {
  width: 24px;
  height: 24px;
  background-position: -340px -60px;
}

.icon-favorite_heart_red {
  width: 24px;
  height: 24px;
  background-position: -340px -88px;
}

.icon-flash-error {
  width: 64px;
  height: 64px;
  background-position: 0px -60px;
}

.icon-flash-info {
  width: 64px;
  height: 64px;
  background-position: -68px -60px;
}

.icon-flash-question {
  width: 64px;
  height: 64px;
  background-position: -136px -60px;
}

.icon-flash-stop {
  width: 64px;
  height: 64px;
  background-position: -204px -60px;
}

.icon-flash-success {
  width: 64px;
  height: 64px;
  background-position: -272px -60px;
}

.icon-flash-warning {
  width: 64px;
  height: 64px;
  background-position: 0px -128px;
}

.product-list-box .product-list-information .cart-box .favore, .icon-heart-empty {
  width: 21px;
  height: 18px;
  background-position: -68px -196px;
}

.product-list-box .product-list-information .cart-box .favore.active, .icon-heart-full {
  width: 21px;
  height: 18px;
  background-position: -93px -196px;
}

.icon-ic-bar {
  width: 30px;
  height: 30px;
  background-position: -307px -128px;
}

.icon-ic-close {
  width: 30px;
  height: 30px;
  background-position: 0px -196px;
}

.icon-icon_fb {
  width: 35px;
  height: 35px;
  background-position: -122px -128px;
}

.icon-icon_ig {
  width: 35px;
  height: 35px;
  background-position: -161px -128px;
}

.icon-icon_like {
  width: 16px;
  height: 16px;
  background-position: -160px -196px;
}

.icon-icon_like_red {
  width: 16px;
  height: 16px;
  background-position: -180px -196px;
}

.icon-icon_line {
  width: 35px;
  height: 35px;
  background-position: -200px -128px;
}

.icon-left-arrow {
  width: 16px;
  height: 32px;
  background-position: 0px -230px;
}

.icon-logo-m {
  width: 193px;
  height: 29px;
  background-position: -174px 0px;
}

.icon-logo {
  width: 170px;
  height: 56px;
  background-position: 0px 0px;
}

.icon-menu-delete {
  width: 24px;
  height: 24px;
  background-position: -341px -128px;
}

.icon-question {
  width: 16px;
  height: 16px;
  background-position: -200px -196px;
}

.icon-recommend_arrow_next {
  width: 14px;
  height: 22px;
  background-position: -76px -230px;
}

.icon-recommend_arrow_prev {
  width: 14px;
  height: 22px;
  background-position: -94px -230px;
}

.icon-right-arrow {
  width: 16px;
  height: 32px;
  background-position: -20px -230px;
}

.icon-search-pc {
  width: 16px;
  height: 16px;
  background-position: -220px -196px;
}

.icon-search {
  width: 20px;
  height: 20px;
  background-position: -298px -33px;
}

.icon-user {
  width: 20px;
  height: 20px;
  background-position: -322px -33px;
}

.product-list-frame .filter-options .view-quantity li a i, .product-list-frame .filter-options .view-quantity li.active a i, .icon-view_black {
  width: 12px;
  height: 14px;
  background-position: -240px -196px;
}

.icon-view_gray {
  width: 12px;
  height: 14px;
  background-position: -256px -196px;
}

.icon-warning {
  width: 30px;
  height: 30px;
  background-position: -34px -196px;
}

.icon, .product-list-box .product-list-information .cart-box .cart-btn, .product-list-box .product-list-information .cart-box .favore.active, .product-list-box .product-list-information .cart-box .favore, .product-list-frame .filter-options .view-quantity li a i, .product-list-frame .filter-options .view-quantity li.active a i, .icon-warning, .icon-view_gray, .icon-view_black, .icon-user, .icon-search, .icon-search-pc, .icon-right-arrow, .icon-recommend_arrow_prev, .icon-recommend_arrow_next, .icon-question, .icon-menu-delete, .icon-logo, .icon-logo-m, .icon-left-arrow, .icon-icon_line, .icon-icon_like_red, .icon-icon_like, .icon-icon_ig, .icon-icon_fb, .icon-ic-close, .icon-ic-bar, .icon-heart-full, .icon-heart-empty, .icon-flash-warning, .icon-flash-success, .icon-flash-stop, .icon-flash-question, .icon-flash-info, .icon-flash-error, .icon-favorite_heart_red, .icon-favorite_heart_gray, .icon-favorite, .icon-delete, .icon-collection-black, .icon-collection-black-m, .icon-close-btn, .icon-clock, .icon-circle-arrow, .icon-cart, .icon-back_arrow, .icon-arrow_prev, .icon-arrow_next, .icon-arrow-top, .icon-ad-close {
  background-image: url(../img/icon.png);
  background-size: 367px 262px;
  background-repeat: no-repeat;
  display: block;
}

/********************************************
- List all sprites of full CSS class, ex:
  @include spritesCSS("icon");

- Insert a single sprite CSS property, ex:
  @extend .icon;
  @extend %icon-file-name;
********************************************/
@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .icon, .product-list-box .product-list-information .cart-box .cart-btn, .product-list-box .product-list-information .cart-box .favore.active, .product-list-box .product-list-information .cart-box .favore, .product-list-frame .filter-options .view-quantity li a i, .product-list-frame .filter-options .view-quantity li.active a i, .icon-warning, .icon-view_gray, .icon-view_black, .icon-user, .icon-search, .icon-search-pc, .icon-right-arrow, .icon-recommend_arrow_prev, .icon-recommend_arrow_next, .icon-question, .icon-menu-delete, .icon-logo, .icon-logo-m, .icon-left-arrow, .icon-icon_line, .icon-icon_like_red, .icon-icon_like, .icon-icon_ig, .icon-icon_fb, .icon-ic-close, .icon-ic-bar, .icon-heart-full, .icon-heart-empty, .icon-flash-warning, .icon-flash-success, .icon-flash-stop, .icon-flash-question, .icon-flash-info, .icon-flash-error, .icon-favorite_heart_red, .icon-favorite_heart_gray, .icon-favorite, .icon-delete, .icon-collection-black, .icon-collection-black-m, .icon-close-btn, .icon-clock, .icon-circle-arrow, .icon-cart, .icon-back_arrow, .icon-arrow_prev, .icon-arrow_next, .icon-arrow-top, .icon-ad-close {
    background-image: url(../img/icon@2x.png);
    background-size: 367px 262px;
  }
}
@media screen and (max-width: 1200x) {
  #content-frame {
    padding: 30px 0;
  }
}

#wrapper {
  background: #fff;
}

/* 瀏覽方式 結束 */
.product-list-frame {
  width: 100%;
  margin: auto auto 60px auto;
  /* 產品列表menu 開始 */
  /* 產品列表menu 結束 */
  /* 篩選項目 開始 */
  /* 篩選項目 結束 */
}
.product-list-frame:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.product-list-frame .product-list-menu {
  width: 210px;
  float: left;
  padding-right: 15px;
}
@media screen and (max-width: 991px) {
  .product-list-frame .product-list-menu {
    width: 100%;
    padding: 0;
  }
}
.product-list-frame .product-list-menu ul.pc {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .product-list-frame .product-list-menu ul.pc {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .product-list-frame .product-list-menu ul.pc li {
    display: inline;
  }
}
.product-list-frame .product-list-menu ul.pc li h2 {
  color: #808080;
  border-bottom: 1px #808080 solid;
  display: block;
  padding: 10px 0;
  font-size: 14px;
  margin: 0 0 10px 0;
  font-family: "Montserrat-Medium", "Noto_Medium";
}
.product-list-frame .product-list-menu ul.pc li ul {
  margin-bottom: 15px;
}
@media screen and (max-width: 991px) {
  .product-list-frame .product-list-menu ul.pc li ul {
    display: none;
  }
}
.product-list-frame .product-list-menu ul.pc li ul li {
  margin-bottom: 10px;
}
.product-list-frame .product-list-menu ul.pc li ul li h3 {
  margin: 0;
  font-weight: bold;
}
.product-list-frame .product-list-menu ul.pc li ul li h3 > a {
  font-size: 14px;
}
.product-list-frame .product-list-menu ul.pc li ul li h3 a {
  font-family: "Montserrat-Medium", "Noto_Medium";
}
.product-list-frame .product-list-menu ul.pc li ul li h4 {
  margin: 0;
}
.product-list-frame .product-list-menu ul.pc li ul li a {
  font-size: 14px;
  color: #0C0C0C;
  letter-spacing: 0.075em;
  line-height: 25px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
}
.product-list-frame .product-list-menu ul.pc li ul li a:hover {
  color: #aaa;
}
.product-list-frame .product-list-menu ul.mobile {
  display: none;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .product-list-frame .product-list-menu ul.mobile {
    display: block;
  }
}
.product-list-frame .product-list-menu ul.mobile h2 {
  font-size: 14px;
  margin: 0;
  display: inline;
}
.product-list-frame .product-list-menu ul.mobile span {
  color: #0C0C0C;
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 45px;
  font-weight: bold;
  border-bottom: 1px #333 solid;
}
.product-list-frame .product-list-menu ul.mobile li {
  display: inline-block;
}
.product-list-frame .product-list-menu ul.mobile li:after {
  content: "/";
  display: inline-block;
  line-height: 24px;
  font-size: 12px;
  color: #0C0C0C;
  padding: 0 2px;
}
.product-list-frame .product-list-menu ul.mobile li:last-child:after {
  content: " ";
}
.product-list-frame .product-list-menu ul.mobile li h3 {
  display: inline;
  font-size: 12px;
  font-weight: normal;
}
.product-list-frame .product-list-menu ul.mobile li a {
  line-height: 24px;
  font-size: 12px;
  color: #0C0C0C;
  letter-spacing: 0.1em;
}
.product-list-frame .product-list-menu ul.mobile li a.active {
  border-bottom: 1px #0C0C0C solid;
}
.product-list-frame .product-list-right {
  float: right;
  width: calc(100% - 210px);
}
.product-list-frame .product-list-right.full {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .product-list-frame .product-list-right {
    width: 100%;
  }
}
.product-list-frame .product-list-right .list-banner img {
  max-width: 100%;
}
.product-list-frame .filter-options {
  margin: auto;
  position: relative;
  padding: 0 5px;
}
.product-list-frame .filter-options:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media screen and (max-width: 991px) {
  .product-list-frame .filter-options {
    height: 50px;
    background: #fff;
    border-bottom: 1px solid #333;
    padding: 0;
  }
}
.product-list-frame .filter-options .filter-project {
  float: left;
  text-align: left;
  margin: 0;
}
@media screen and (max-width: 991px) {
  .product-list-frame .filter-options .filter-project {
    width: 100%;
    display: flex;
  }
}
.product-list-frame .filter-options .filter-project li {
  padding: 6px 0 9px 0;
  display: inline-block;
}
@media screen and (max-width: 991px) {
  .product-list-frame .filter-options .filter-project li {
    width: 50%;
    text-align: center;
    padding: 15px 0;
  }
}
.product-list-frame .filter-options .filter-project span {
  color: #0C0C0C;
  font-size: 12px;
  font-family: Montserrat-Medium;
}
@media screen and (max-width: 1024px) {
  .product-list-frame .filter-options .filter-project.fix {
    position: fixed;
    top: 60px;
    z-index: 2;
    border-bottom: 1px solid #0C0C0C;
    background: #fff;
    left: 0;
  }
}
.product-list-frame .filter-options .filter-project-view label {
  color: #c5c5c5;
  font-size: 12px;
  cursor: pointer;
  padding: 0 5px;
}
.product-list-frame .filter-options .filter-project-view label:after, .product-list-frame .filter-options .filter-project-view label:before {
  display: none;
}
.product-list-frame .filter-options .filter-project-view input {
  position: absolute;
  left: -9999px;
}
.product-list-frame .filter-options .filter-project-view input:checked + label {
  color: #0C0C0C;
  text-decoration: underline;
}
.product-list-frame .filter-options select {
  background: url(../img/select-arrow.png) no-repeat right 7px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 110px;
  border: 0;
  outline: none;
  font-size: 12px;
  color: #0C0C0C;
  padding: 0 10px;
  cursor: pointer;
  background-size: 22px 8px;
  font-family: Montserrat-Medium;
}
@media screen and (max-width: 991px) {
  .product-list-frame .filter-options select {
    text-align-last: center;
    width: 100%;
    padding: 0 5px;
  }
}
.product-list-frame .filter-options .view-quantity {
  width: 100px;
  float: right;
  height: 38px;
  position: relative;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.product-list-frame .filter-options .view-quantity li {
  display: inline-block;
}
.product-list-frame .filter-options .view-quantity li:nth-child(1):after {
  content: "|";
  display: inline-block;
  font-size: 14px;
  position: relative;
  top: 0px;
  padding: 0 5px;
}
.product-list-frame .filter-options .view-quantity li:last-child a i {
  margin-right: 0;
}
.product-list-frame .filter-options .view-quantity li.active a i {
  display: inline-block;
}
.product-list-frame .filter-options .view-quantity li a i {
  display: inline-block;
  margin: 1px;
  vertical-align: bottom;
}

/* 產品列表 開始 */
.product-list-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.product-list-box.view-4 .product-list-content {
  width: 25%;
  margin-bottom: 20px;
  padding: 0 4px;
}
@media screen and (max-width: 800px) {
  .product-list-box.view-4 .product-list-content {
    width: 50%;
  }
}
.product-list-box.view-2 .product-list-content {
  width: 50%;
  margin-bottom: 20px;
  padding: 0 4px;
}
@media screen and (max-width: 800px) {
  .product-list-box.view-2 .product-list-content {
    width: 50%;
    padding: 0 5px;
  }
}
.product-list-box .commodity-box {
  position: relative;
  overflow: hidden;
}
.product-list-box .commodity-box .sale-icon {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 10px;
  z-index: 2;
  left: 10px;
}
.product-list-box .commodity-box .sale-icon img {
  width: 80px;
  height: 80px;
}
@media screen and (max-width: 1024px) {
  .product-list-box .commodity-box .sale-icon {
    width: 50px;
    height: 50px;
  }
  .product-list-box .commodity-box .sale-icon img {
    width: 50px;
    height: 50px;
  }
}
.product-list-box .commodity-box .commodity-img {
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: block;
}
.product-list-box .commodity-box .commodity-img img {
  width: 100%;
}
.product-list-box .commodity-box .commodity-img .sold {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat-Medium;
  z-index: 1;
}
.product-list-box .product-list-information {
  margin: 10px 0;
  font-size: 14px;
  padding: 0 20px;
}
@media screen and (max-width: 991px) {
  .product-list-box .product-list-information {
    padding: 0;
  }
}
.product-list-box .product-list-information .product-list-tltie {
  margin-bottom: 10px;
  text-align: center;
}
.product-list-box .product-list-information .product-list-tltie h2 {
  font-size: 12px;
  margin: 0;
  font-weight: normal;
}
.product-list-box .product-list-information .product-list-tltie span {
  color: #0C0C0C;
  font-size: 12px;
  letter-spacing: 0.2em;
  line-height: 20px;
  border: 1px solid #777;
  padding: 0 5px;
  font-family: "Montserrat-Medium", " Noto_Medium";
  display: inline-block;
}
.product-list-box .product-list-information .product-list-size label {
  color: #0C0C0C;
  margin: 0 0.3em;
  cursor: pointer;
  padding: 0;
  font-family: "Montserrat-Medium", " Noto_Medium";
}
.product-list-box .product-list-information .product-list-size label:before, .product-list-box .product-list-information .product-list-size label:after {
  display: none;
}
.product-list-box .product-list-information .product-list-size label:hover {
  text-decoration: underline;
}
.product-list-box .product-list-information .product-list-size input {
  position: absolute;
  left: -9999px;
}
.product-list-box .product-list-information .product-list-size input:checked + label {
  text-decoration: underline;
}
.product-list-box .product-list-information .product-list-size input[disabled] + label {
  color: #c5c5c5;
}
.product-list-box .product-list-information .product-list-name h1 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
.product-list-box .product-list-information .product-list-name a {
  color: #0C0C0C;
  letter-spacing: 0.2em;
  padding: 9px 0;
  font-family: "Montserrat-Medium", " Noto_Medium";
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product-list-box .product-list-information .product-list-price {
  position: relative;
  padding-right: 70px;
}
.product-list-box .product-list-information .product-list-price span {
  color: #0C0C0C;
  letter-spacing: 0.2em;
  margin-right: 10px;
  font-family: "Montserrat-Medium", " Noto_Medium";
}
.product-list-box .product-list-information .product-list-price span.original-price {
  color: #0C0C0C;
  text-decoration: line-through;
}
.product-list-box .product-list-information .product-list-price span.sale {
  color: #c1272d;
  margin: 5px 0;
}
.product-list-box .product-list-information .cart-box {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  margin: auto;
  align-items: center;
}
.product-list-box .product-list-information .cart-box .favore {
  text-indent: -999px;
  overflow: hidden;
  margin-right: 10px;
}
.product-list-box .product-list-information .cart-box .cart-btn {
  text-indent: -999px;
  overflow: hidden;
}

/* 產品列表 結束 */
.display-in {
  display: none;
}
@media screen and (max-width: 991px) {
  .display-in {
    display: inline !important;
  }
}